import React from 'react'
import { Page, Section, Story } from '@components'

const metaData = {
  title: 'story.seo.title',
  description: 'story.seo.description'
}

const b2b = props => {
  const { locale } = props.pageContext
  return (
    <Page locale={locale} meta={metaData}>
      <Section>
        <Story />
      </Section>
    </Page>
  )
}

export default b2b
